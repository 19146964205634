import React, { useContext, useState, useEffect } from 'react';

import cn from 'classnames';

import { OrderContext } from '../../../../client/javascript/context/OrderContext';
import usePopup from '../../../../hooks/use-popup';
import IconSvg from '../IconSvg/IconSvg';

import MemberIcon from '../../../../../icons/member.svg';

import * as styles from './QrBraclet.module.scss';
import { declOfNum } from '../../api';
import Action from '../../../../client/javascript/components/Action';
import Popup from '../../../../client/javascript/components/Popup';
import QrBraclet from './QrBraclet';
import useWs from '../../../../hooks/use-ws';
import useAuth from '../../../../hooks/use-auth';

export const NOTIFY_OF_BRACLET = {
  SUCCESS: 'success',
  FAIL: 'fail'
};

const QrBracletListMembers = () => {
  const { order, orderMembers, isOrderPaid } = useContext(OrderContext);

  if (!order.id) return '';

  const { isLoggedIn, user } = useAuth();
  const [isPopupOpened, openPopup, closePopup] = usePopup();
  const [isQrBracletOpened, openQrBraclet, closeQrBraclet] = usePopup();
  const [currentMember, setCurrentMember] = useState(null);
  const [pushBraclet, setPushBraclet] = useState(null);
  const [ready, msg, send] = useWs(order.id);

  const handleOpenMembers = () => {
    openPopup();
  };

  // const handleOpenForm = (member) => {
  //   setCurrentMember(member);
  //   openQrBraclet();
  // };

  const handleCloseQrBraclet = () => {
    setCurrentMember(null);
    closeQrBraclet();
  };

  const handleAllReceiveBraclet = () => {
    const memberIds = orderMembers.map(member => member.id);

    setCurrentMember({ id: memberIds, participant: 'Все участники' });
    openQrBraclet();
  };

  useEffect(() => {
    if (ready && NOTIFY_OF_BRACLET.SUCCESS === msg?.notify) {
      console.log(msg.result);
      setPushBraclet(msg.result);
    }
  }, [msg]);

  return (
    <>
      {!isPopupOpened && (
        <Action
          themes={['Button', 'ButtonSmall', 'Fullwidth']}
          onClick={isOrderPaid ? handleOpenMembers : null}
        >
          {isOrderPaid && 'Получить браслеты'}
          {!isOrderPaid && (
            <>
              <span>Получить браслеты&nbsp;</span>
              <div className={styles.QrBracletListMembers__totalCost}>
                <span>Необходимо оплатить&nbsp;</span>
                {order.total_cost_to_s}
              </div>
            </>
          )}
        </Action>
      )}

      <Popup
        show={isPopupOpened}
        onClose={closePopup}
        escapeClose={false}
        outsideClose={false}
        variant='medium'

        footer={
          (
            <div className={styles.QrBracletListMembers__footerBtn}>
              {(!(!pushBraclet && order.check_braclet_count === order.members_count)
                && !(pushBraclet && pushBraclet?.length === order.members_count)) && (
                <Action themes={['Button', 'ButtonSmall', 'ButtonCommon', 'Fullwidth']} onClick={handleAllReceiveBraclet}>
                  {order.members_count === 1 && 'Получить браслет'}
                  {order.members_count > 1 && 'Получить браслеты за всех'}
                </Action>
              )}
            </div>
          )
        }
      >
        <ul className={styles.QrBracletListMembers}>
          {orderMembers.map(member => (
            <div className={styles.QrBracletListMembers__row}>
              <li key={member.id}>
                <div className={styles.QrBracletListMembers__wrapHead}>
                  <div>
                    {member?.participant}
                  </div>
                  <div>
                    {((!pushBraclet && member.check_braclet) || (pushBraclet && pushBraclet?.find(b => b.member_id === member.id)?.notice)) && 'Браслет выдан'}
                  </div>
                </div>
                <div className={styles.QrBracletListMembers__wrapMember}>
                  <IconSvg icon={MemberIcon} className={cn({ [styles.QrBracletListMembers__filled]: member?.filled })} />
                  <div className={styles.QrBracletListMembers__fio}>
                    <div>
                      {member?.name || 'Имя'}
                      &nbsp;
                      {member?.surname || 'Фамилия'}
                    </div>
                    <div>
                      {member?.age ? `${member?.age} ${declOfNum(member?.age, ['год', 'года', 'лет'])}` : 'Нет данных'}
                      &nbsp;
                      {member?.phone}
                    </div>
                  </div>
                </div>
              </li>
              {/* <li>
                {(!(!pushBraclet && member.check_braclet) && !(pushBraclet && pushBraclet?.find(b => b.member_id === member.id)?.notice)) && (
                  <Action
                    id={member.id}
                    themes={['Button', 'ButtonSmall', 'ButtonCommon', 'ButtonWarning']}
                    onClick={() => handleOpenForm(member)}
                  >
                    получить браслет
                  </Action>
                )}
              </li> */}
            </div>
          ))}
        </ul>
      </Popup>

      {currentMember && (
        <Popup
          show={isQrBracletOpened}
          onClose={handleCloseQrBraclet}
          header={(
            <>
              {(currentMember?.name && currentMember?.surname) && (
                <h4>
                  {currentMember?.name}
                  &nbsp;
                  {currentMember?.surname}
                  {currentMember?.age && `, ${currentMember?.age} ${declOfNum(currentMember?.age, ['год', 'года', 'лет'])}`}
                </h4>
              )}
              {(!currentMember?.name && !currentMember?.surname) && (
                <h4>
                  {currentMember?.participant}
                  {currentMember?.age && `, ${currentMember?.age} ${declOfNum(currentMember?.age, ['год', 'года', 'лет'])}`}
                </h4>
              )}
            </>
          )}
          variant='small'
        >
          <QrBraclet QrBracletLink={`pnr=${order.pnr}&recipient[phone]=${user.phone}&member_ids=${currentMember.id}`} />
        </Popup>
      )}
    </>
  );
};

export default QrBracletListMembers;
