import React, { useContext, useEffect } from 'react';

import { OrderContext } from '../../../../context/OrderContext';

import Action from '../../../Action';
import Popup from '../../../Popup';
import usePopup from '../../../../../../hooks/use-popup';

import * as styles from './PaymentSchedule.module.scss';
import PaymentForm from '../../PaymentForm';
import PaidForm from '../../PaidForm';
import { numberToMoney } from '../../../../../../helpers/currency';
import PromoCodeForm from '../../PromoCodeForm/PromoCodeForm';
import AnnuledTemplateForm from './AnnuledTemplateForm/AnnuledTemplateForm';
import { ReachGoal } from '../../../../YandexMetrika';

export const NOTIFY_OF_PAY = {
  SUCCESS: 'success',
  FAIL: 'fail'
};

const PaymentSchedule = () => {
  const {
    order,
    isOrderPaid,
    hasNotifyOfPay,
    setHasNotifyOfPay
  } = useContext(OrderContext);
  const [isAnnuledOpened, openAnnuled, closeAnnuled] = usePopup();
  const [isPaymentOpened, openPayment, closePayment] = usePopup();
  const [isPaidOpened, openPaid, closePaid] = usePopup();
  const [isPromoOpened, openPromo, closePromo] = usePopup();

  const handleAnnuled = () => {
    openAnnuled();
  };

  const handlePayment = () => {
    if (order.click_btn_pay_target) ReachGoal(order.click_btn_pay_target, { offer: order.offer_name });
    openPayment();
  };

  const handlePaid = () => {
    openPaid();
  };

  const handlePromo = () => {
    openPromo();
  };

  const handlePromoClose = () => {
    closePromo();
  };

  useEffect(() => {
    if (NOTIFY_OF_PAY.SUCCESS === hasNotifyOfPay) {
      handlePaid();
      setHasNotifyOfPay('');
    }
  }, []);

  return (
    <>
      <div className={styles.PaymentSchedule}>
        <div className={styles.PaymentSchedule__right}>
          {order.discount_money > 0 && (
            <div className={styles.PaymentSchedule__row}>
              <div>Скидка</div>
              <div>{`-${numberToMoney(order.discount_money)}`}</div>
            </div>
          )}
          {order.used_promo_code && (
            <div className={styles.PaymentSchedule__row}>
              <div>{`Код ${order.used_promo_code.code}`}</div>
              <div>{`-${numberToMoney(order.used_promo_code.monetary_value)}`}</div>
            </div>
          )}
          <div className={styles.PaymentSchedule__row}>
            <div>Тур</div>
            <div>{numberToMoney(order.cost)}</div>
          </div>
          <div className={styles.PaymentSchedule__row}>
            <div>Доп. услуги</div>
            <div>{numberToMoney(order.additional_cost)}</div>
          </div>
          <div className={styles.PaymentSchedule__total}>
            <div>Сумма</div>
            <div>{numberToMoney((order.total_cost <= 0 ? 0 : order.total_cost))}</div>
          </div>
        </div>

        {order.status_cd < 2 && (
          <>
            {(!order.product_order_wait || order.amount_paid >= 0) && (
              <div className={styles.PaymentSchedule__paidLink}>
                <Action
                  onClick={handlePaid}
                  themes={['Link']}
                >
                  {order.amount_paid > 0 && (
                    `оплачено ${numberToMoney(order.amount_paid)}`
                  )}
                  {order.amount_paid === 0 && (
                    'Платите частями'
                  )}
                </Action>
              </div>
            )}
            <div className={styles.PaymentSchedule__annuledLink}>
              <Action
                themes={['Link']}
                onClick={handleAnnuled}
              >
                Отменить заказ
              </Action>
            </div>
          </>
        )}
      </div>
      <div className={styles.PaymentSchedule__sticky}>
        <div className={styles.PaymentSchedule__paid}>
          {!isOrderPaid && <div className={styles.PaymentSchedule__totalCost}>{order.total_cost_to_s}</div>}
          {order.has_promo && !order.used_promo_code && (
            <Action
              onClick={handlePromo}
              themes={['Link']}
              className={styles.PaymentSchedule__paidLink}
            >
              промокод
            </Action>
          )}
        </div>
        {(!isOrderPaid && order.status_cd < 2) && (
          <div className={styles.PaymentSchedule__paidBtn}>
            <Action
              onClick={order.status_cd !== 1 ? null : handlePayment}
              themes={order.status_cd !== 1 ? ['Button', 'Fullwidth', 'ButtonSecondary', 'ButtonCommon'] : ['Button', 'Fullwidth', 'ButtonCommon', 'ButtonWarning']}
            >
              Оплатить
            </Action>
          </div>
        )}
      </div>

      <Popup
        show={isAnnuledOpened}
        onClose={closeAnnuled}
        variant='small'
      >
        <AnnuledTemplateForm onClose={closeAnnuled} />
      </Popup>

      <Popup
        show={isPaymentOpened}
        onClose={closePayment}
        escapeClose={false}
        outsideClose={false}
        variant='small'
      >
        <PaymentForm openPaid={openPaid} closePaymentForm={closePayment} />
      </Popup>

      <Popup
        show={isPaidOpened}
        onClose={closePaid}
        variant='small'
      >
        <PaidForm />
      </Popup>

      <Popup
        show={isPromoOpened}
        onClose={closePromo}
        variant='small'
      >
        <PromoCodeForm onClose={handlePromoClose} />
      </Popup>
    </>
  );
};


PaymentSchedule.propTypes = {

};


export default PaymentSchedule;
