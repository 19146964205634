import React from 'react';
import PropTypes from 'prop-types';

import { QRCodeSVG } from 'qrcode.react';

import * as styles from './QrBraclet.module.scss';
import FormsRow from '../../../../client/javascript/components/FormsRow';

const QrBraclet = ({
  QrBracletLink,
  onClose
}) => {
  const qrTimeoutMessage = (
    <FormsRow>
      <div className={styles.QrBraclet__qrMessage}>
        <a
          rel='noopener noreferrer'
          href='https://rosafest.ru/help/contacts'
          target='_blank'
        >
          Где получить браслет?
        </a>
      </div>
    </FormsRow>
  );

  return (
    <div className={styles.QrBraclet__qr}>
      <QRCodeSVG
        value={QrBracletLink}
        size={128}
        bgColor='#ffffff'
        fgColor='#1d1346'
        level='H'
        includeMargin={false}
      />

      {qrTimeoutMessage}
    </div>
  );
};


QrBraclet.propTypes = {
  QrBracletLink: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};


export default QrBraclet;
