import React, { useContext, useState } from 'react';

import { OrderContext } from '../../../../context/OrderContext';
import IconSvg from '../../../../../../common/javascript/components/IconSvg/IconSvg';

import CalendarIcon from '../../../../../../../icons/calendar-new.svg';
import PeronsIcon from '../../../../../../../icons/persons.svg';
import AnnuledIcon from '../../../../../../../icons/circle-red.svg';
import ConfirmedIcon from '../../../../../../../icons/circle-green.svg';
import WaitingIcon from '../../../../../../../icons/circle-grey.svg';
import LearnedFromType from '../../LearnedFromType/LearnedFromType';

import * as styles from './OrderHeader.module.scss';
import Action from '../../../Action';
import QrBracletListMembers from '../../../../../../common/javascript/components/QrBraclet/QrBracletListMembers';

const Icon = [
  WaitingIcon,
  ConfirmedIcon,
  AnnuledIcon,
  AnnuledIcon,
  AnnuledIcon
];

const OrderHeader = () => {
  const { order } = useContext(OrderContext);
  const [isCopied, setIsCopied] = useState(false);

  if (!order.id) return '';

  const copyTextToClipboard = async (text) => {
    if ('clipboard' in navigator) {
      const cliboardText = await navigator.clipboard.writeText(text);
      return cliboardText;
    }

    return document.execCommand('copy', true, text);
  };

  const handlePnrClick = (e) => {
    const copyText = e.target.innerText.replace('№ ', '');

    copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className={styles.OrderHeader}>
        {order.pre_order_description && (
          <div className={styles.OrderHeader__preOrderWrap}>
            <span>
              {order.pre_order_description}
            </span>
          </div>
        )}
        <div className={styles.OrderHeader__head}>
          <Action
            className={styles.OrderHeader__pnr}
            onClick={handlePnrClick}
          >
            {`№ ${order.pnr}`}
            {isCopied && (
              <div
                className={styles.OrderHeader__copyPnr}
              >
                скопировано
              </div>
            )}
          </Action>
          <div className={styles.OrderHeader__status}>
            {order.status}
            <IconSvg icon={Icon[order.status_cd]} />
          </div>
        </div>
        <div className={styles.OrderHeader__info}>
          <div className={styles.OrderHeader__name}>
            {order.offer_name}
          </div>
          <div className={styles.OrderHeader__wrap}>
            <div className={styles.OrderHeader__spot}>
              {order.spot}
            </div>
            <div className={styles.OrderHeader__icon}>
              <IconSvg icon={PeronsIcon} className={styles.OrderHeader__iconCalendar} />
            </div>
            <div className={styles.OrderHeader__wrap__text}>
              {order.members_count}
            </div>
          </div>
        </div>
        <div className={styles.OrderHeader__datePersons}>
          <div className={styles.OrderHeader__wrap}>
            <div className={styles.OrderHeader__icon}>
              <IconSvg icon={CalendarIcon} className={styles.OrderHeader__iconCalendar} />
            </div>
            <div className={styles.OrderHeader__wrap__text}>
              <span>{order.period_to_s}</span>
              <span>{order.duration_to_s}</span>
            </div>
          </div>
        </div>
      </div>

      <LearnedFromType />

      {order.for_quest && order.status_cd === 1 && order.members_count >= 1 && (
        <div className={styles.OrderHeader__braclet}>
          <QrBracletListMembers />
        </div>
      )}
    </>
  );
};

export default OrderHeader;
