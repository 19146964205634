import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Action from '../../../Action';
import Checkbox from '../../../Checkbox';
import { numberToMoneyWithoutCurrency } from '../../../../../../helpers/currency';

import * as styles from './SubOrderPeopleSelect.module.scss';

const SubOrderPeopleSelect = ({
  nodes,
  prefix,
  onClose
}) => {
  const [checkedItems, setCheckedItems] = useState([]);

  const handleInputChange = (isChecked, memberId) => {
    if (isChecked) setCheckedItems(checkedItems.filter(f => f !== memberId));
    if (!isChecked) setCheckedItems([].concat(checkedItems, [memberId]));
  };

  const handleClearAll = () => {
    setCheckedItems([]);
  };

  const handleSave = () => {
    onClose(checkedItems);
  };

  useEffect(() => {
    setCheckedItems(nodes.activeMembersIds);
  }, []);

  return (
    <div
      className={styles.SubOrderPeopleSelect}
    >
      {/* <div className={styles.SubOrderPeopleSelect__title}>{nodes.subOrderName}</div> */}
      <div className={styles.SubOrderPeopleSelect__group}>
        {nodes.members.map(node => (
          <div className={styles.SubOrderPeopleSelect__row}>
            <Checkbox
              label={node.member_name}
              id={`${prefix}_CheckBox_${node.member_id}`}
              name={`${prefix}_CheckBoxInputName_${node.member_id}`}
              type='checkbox'
              checked={checkedItems.includes(node.member_id)}
              onChange={
                () => {
                  const isChecked = checkedItems.includes(node.member_id);
                  handleInputChange(isChecked, node.member_id);
                }
              }
              value={node.member_id}
            />
            {(node?.regular_participant_cost !== 0) && (
              <div className={styles.SubOrderPeopleSelect__groupDiscount}>
                <div className={styles.SubOrderPeopleSelect__discountFigure}>
                  {/* {`${numberToMoneyWithoutCurrency(node?.cost)} ${nodes.currencySign}`} */}
                  <div className={styles.SubOrderPeopleSelect__cost}>{numberToMoneyWithoutCurrency(node?.cost)}</div>
                  <div className={styles.SubOrderPeopleSelect__currency}>{nodes.currencySign}</div>
                </div>
                <div className={styles.SubOrderPeopleSelect__price}>
                  <div className={styles.SubOrderPeopleSelect__cost}>{numberToMoneyWithoutCurrency(node?.regular_participant_cost)}</div>
                  <div className={styles.SubOrderPeopleSelect__currency}>{nodes.currencySign}</div>
                </div>
              </div>
            )}
            {(node?.regular_participant_cost === 0) && (
              <div className={styles.SubOrderPeopleSelect__price}>
                <div className={styles.SubOrderPeopleSelect__cost}>{numberToMoneyWithoutCurrency(node?.cost)}</div>
                <div className={styles.SubOrderPeopleSelect__currency}>{nodes.currencySign}</div>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className={styles.SubOrderPeopleSelect__group_btn}>
        <Action
          to='/'
          onClick={handleClearAll}
        >
          ОЧИСТИТЬ
        </Action>
        <Action
          themes={['Button', 'ButtonCommon', 'ButtonWarning']}
          onClick={handleSave}
          // disabled={checkedItems.length === 0}
        >
          сохранить
        </Action>
      </div>
    </div>
  );
};

SubOrderPeopleSelect.propTypes = {
  nodes: PropTypes.shape({
    subOrderName: PropTypes.string,
    regular_participant_cost: PropTypes.number
  }).isRequired,
  prefix: PropTypes.string,
  onClose: PropTypes.func.isRequired
};

SubOrderPeopleSelect.defaultProps = {
  prefix: '_'
};

export default SubOrderPeopleSelect;
