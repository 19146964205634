import React, { useContext, useState } from 'react';

import cn from 'classnames';

import { OrderContext } from '../../../../context/OrderContext';
import IconSvg from '../../../../../../common/javascript/components/IconSvg/IconSvg';
import Action from '../../../Action';
import Popup from '../../../Popup';
import usePopup from '../../../../../../hooks/use-popup';
import MemberForm from '../../MemberForm';
import { declOfNum } from '../../../../../../common/javascript/api';

import MemberIcon from '../../../../../../../icons/member.svg';

import * as styles from './Members.module.scss';
import InviteOnPhone from '../../InviteOnPhone/InviteOnPhone';
import Checkbox from '../../../Checkbox';
import useAuth from '../../../../../../hooks/use-auth';

const Members = () => {
  const {
    order,
    orderMembers,
    handleUpdateMember
  } = useContext(OrderContext);

  if (!order.id) return '';

  const [isPopupOpened, openPopup, closePopup] = usePopup();
  const [isInviteOpened, openInvite, closeInvite] = usePopup();
  const [currentMember, setCurrentMember] = useState(null);
  const [prepareData, setPrepareData] = useState(true);
  const { user, isLoggedIn } = useAuth();
  const isOwner = user.id === order?.owner?.id;

  const handleOpenForm = (member) => {
    setCurrentMember(member);
    openPopup();
  };

  const handleOpenInviteForm = (member) => {
    setCurrentMember(member);
    openInvite();
  };

  const handleFormSubmit = (values, setSubmitting, cerrors) => {
    // alert(JSON.stringify(values, null, 2));

    handleUpdateMember({ ...values, in_profile: prepareData }, setSubmitting, (result) => {
      if (result.status === 'ok') {
        closePopup();
      }

      if (result.status === 'error') {
        cerrors(result.error);
      }
    });
  };

  return (
    <>
      <ul className={styles.Members}>
        {orderMembers.map(member => (
          <div className={styles.Members__row}>
            <li key={member.id}>
              <div className={styles.Members__wrapHead}>
                <div>
                  {member?.participant}
                </div>
                <div>
                  {member?.invite === 'pending' && 'Приглашение отправлено'}
                </div>
              </div>
              <div className={styles.Members__wrapMember}>
                <IconSvg icon={MemberIcon} className={cn({ [styles.Members__filled]: member?.filled })} />
                <div className={styles.Members__fio}>
                  <div>
                    {member?.name || 'Имя'}
                    &nbsp;
                    {member?.surname || 'Фамилия'}
                  </div>
                  <div>
                    {member?.age ? `${member?.age} ${declOfNum(member?.age, ['год', 'года', 'лет'])}` : 'Нет данных'}
                    &nbsp;
                    {member?.phone}
                  </div>
                </div>
              </div>
            </li>
            <li>
              {(isOwner || (isLoggedIn && !member.firstMember)) && (
                <Action
                  id={member.id}
                  themes={['Button', 'ButtonSmall', 'ButtonCommon', 'ButtonWarning']}
                  onClick={() => handleOpenForm(member)}
                >
                  заполнить данные
                </Action>
              )}
              &nbsp;
              {(member?.invite === 'not_send' && (member.age || 14) > 13 && ((isOwner || isLoggedIn) && !member.firstMember)) && (
                <Action
                  id={member.id}
                  themes={['Button', 'ButtonSmall', 'ButtonCommon', 'ButtonWarning']}
                  onClick={() => handleOpenInviteForm(member)}
                >
                  пригласить
                </Action>
              )}
            </li>
            {((isOwner && member.firstMember) || (user.phone === member.phone && !member.firstMember)) && (
              <li>
                <div className={styles.Members__checkbox}>
                  <Checkbox
                    label='Копировать данные профиля'
                    id={`id-member-checkbox-${member.id}`}
                    name={`member-checkbox-${member.id}`}
                    type='checkbox'
                    checked={prepareData}
                    onClick={() => setPrepareData(!prepareData)}
                  />
                </div>
              </li>
            )}
          </div>
        ))}
      </ul>

      <Popup
        show={isPopupOpened}
        onClose={closePopup}
        escapeClose={false}
        outsideClose={false}
        variant='medium'
        header={(<h3>{currentMember?.participant}</h3>)}
        footer={
          (
            <Action themes={['Button', 'Fullwidth']} type='submit' form={currentMember?.id}>
              Сохранить
            </Action>
          )
        }
      >
        <MemberForm
          member={currentMember}
          prepareData={
            (currentMember?.profile?.id === user?.id && !currentMember?.firstMember && prepareData)
              || (isOwner && currentMember?.firstMember && prepareData)
          }
          onSubmit={handleFormSubmit}
        />
      </Popup>

      <Popup
        show={isInviteOpened}
        onClose={closeInvite}
        variant='small'
      >
        <InviteOnPhone
          onClose={closeInvite}
          memberId={currentMember?.id}
        />
      </Popup>
    </>
  );
};

export default Members;
